<template>
  <div>
    <template>
      <div :dir="$t('clan')=='ar'?'rtl':'ltr'">
        <div class="rows">
          <div class="col-md-12">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">{{$t('ScheduleManager')}}</h3>
                </div>
               <!-- <span class="add">
                  <router-link to="/admin/schedule-manager/create">
                    <b-button variant="warning"
                      ><i class="fas fa-plus-circle text-white mr-2"  v-if="$t('clan')=='en'"></i
                      >
                      <i class="fas fa-plus-circle text-white ml-2"  v-else></i
                      >{{$t('New')}}</b-button
                    >
                  </router-link>
                </span>-->
              </div>
              <div class="card-body">
                <div>
                  <ScheduleTable :weekDays="weekDays"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
 

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import QueuesTable from "@/view/pages/Queues/QueuesTable.vue";
import ScheduleTable from '@/view/pages/ScheduleManager/ScheduleTable.vue'
//import moment from 'moment'

export default {
  name: 'TarrifManager',

  components: { ScheduleTable },

  data() {
    return {
      weekDays:[]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "ScheduleManager", route: "/admin/schedule-manager" }]);
    //loads in layout, to reduce the delay

   /* var startOfWeek = moment().startOf('week');
var endOfWeek = moment().endOf('isoWeek');

var days = [];
var day = startOfWeek;

while (day <= endOfWeek) {
    days.push(day.toISOString().split('-')[1]+'/'+day.toISOString().split('-')[2].split('T')[0]);
    day = day.clone().add(1, 'd');
}

//console.log(days);
this.weekDays = days
    //console.log(days)*/

    var curr = new Date(); // get current date
var sun = curr.getDate() - curr.getDay();
var mon = sun + 1 // First day is the day of the month - the day of the week
var tue = mon + 1
var wed = tue + 1
var thu = wed + 1 
var fri = thu + 1
var sat = fri + 1
//var sat = sun + 6; // last day is the first day + 6

var sunday = new Date(curr.setDate(sun)).toISOString();
var monday = new Date(curr.setDate(mon)).toISOString();
var tuseday = new Date(curr.setDate(tue)).toISOString();
var wedensday = new Date(curr.setDate(wed)).toISOString();
var thursday = new Date(curr.setDate(thu)).toISOString();
var friday = new Date(curr.setDate(fri)).toISOString();
var saturday = new Date(curr.setDate(sat)).toISOString();
//var lastday = new Date(curr.setDate(last)).toUTCString();
this.weekDays.push(sunday.split('-')[1]+'/'+sunday.split('-')[2].split('T')[0])
this.weekDays.push(monday.split('-')[1]+'/'+monday.split('-')[2].split('T')[0])
this.weekDays.push(tuseday.split('-')[1]+'/'+tuseday.split('-')[2].split('T')[0])
this.weekDays.push(wedensday.split('-')[1]+'/'+wedensday.split('-')[2].split('T')[0])
this.weekDays.push(thursday.split('-')[1]+'/'+thursday.split('-')[2].split('T')[0])
this.weekDays.push(friday.split('-')[1]+'/'+friday.split('-')[2].split('T')[0])
this.weekDays.push(saturday.split('-')[1]+'/'+saturday.split('-')[2].split('T')[0])
//console.log(this.weekDays )
//console.log(firstday + lastday)
  },
  
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
</style>