<template>
  <div>
    <div>
      <b-table
        :items="table.items"
        :fields="table.fields"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >
      <template #empty>
          <div v-if="isBusy" class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="spinnerClass"
            ></b-spinner>
          </div>
          <p v-else class="text-center muted mb-0 mt-2" style="font-size: 13px">
            {{ $t("There are no records to show") }}
          </p>
        </template>

        <template #cell(full_name)="row">
          <div class="w-150px">
            <span @click="driverPage(row.item.id)" class="trigger">{{row.item.full_name}}</span>
          </div>
        </template>

        <template #thead-top>
          <b-tr>
          <b-th variant="secondary" class="border" ><span class="">Days</span></b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">Sunday</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">monday</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">Tuesday</b-th>
          <b-th variant="secondary" class="border text-center " colspan="2">Wedensday</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">Thursday</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">Friday</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">Staurday</b-th>
        </b-tr>
        <b-tr>
          <b-th variant="secondary" class="border" ><span class="">Date</span></b-th>
          <b-th variant="secondary" class="border text-center " colspan="2">{{weekDays[0]}}</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">{{weekDays[1]}}</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">{{weekDays[2]}}</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">{{weekDays[3]}}</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">{{weekDays[4]}}</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">{{weekDays[5]}}</b-th>
          <b-th variant="secondary" class="border text-center" colspan="2">{{weekDays[6]}}</b-th>
        </b-tr>
      </template>

      <template #cell(sundayfrom)="row">
        <div :class="row.item.working_days.Sunday.from=='off'?'bg-warning':''" style="font-size:11px">

        
          {{row.item.working_days.Sunday.from!=="off"?((new Date(row.item.working_days.Sunday.from)).getHours()>12?new Date(row.item.working_days.Sunday.from).getHours()-12:new Date(row.item.working_days.Sunday.from).getHours()) +
            ':'+((new Date(row.item.working_days.Sunday.from).getMinutes())>10?(new Date(row.item.working_days.Sunday.from).getMinutes()):'0'
            +(new Date(row.item.working_days.Sunday.from).getMinutes()))+(new Date(row.item.working_days.Sunday.from).getHours()>=12?' PM':' AM'):'off'}}
            </div>
        </template>
        <template #cell(sundayto)="row">
          <div :class="row.item.working_days.Sunday.to=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Sunday.to!=="off"?((new Date(row.item.working_days.Sunday.to)).getHours()>12?new Date(row.item.working_days.Sunday.to).getHours()-12:new Date(row.item.working_days.Sunday.to).getHours()) +
            ':'+((new Date(row.item.working_days.Sunday.to).getMinutes())>10?(new Date(row.item.working_days.Sunday.to).getMinutes()):'0'
            +(new Date(row.item.working_days.Sunday.to).getMinutes()))+(new Date(row.item.working_days.Sunday.to).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>

        <template #cell(mondayfrom)="row">
          <div :class="row.item.working_days.Monday.from=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Monday.from!=="off"?((new Date(row.item.working_days.Monday.from)).getHours()>12?new Date(row.item.working_days.Monday.from).getHours()-12:new Date(row.item.working_days.Monday.from).getHours()) +
            ':'+((new Date(row.item.working_days.Monday.from).getMinutes())>10?(new Date(row.item.working_days.Monday.from).getMinutes()):'0'
            +(new Date(row.item.working_days.Monday.from).getMinutes()))+(new Date(row.item.working_days.Monday.from).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>
        <template #cell(mondayto)="row">
          <div :class="row.item.working_days.Monday.to=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Monday.to!=="off"?((new Date(row.item.working_days.Monday.to)).getHours()>12?new Date(row.item.working_days.Monday.to).getHours()-12:new Date(row.item.working_days.Monday.to).getHours()) +
            ':'+((new Date(row.item.working_days.Monday.to).getMinutes())>10?(new Date(row.item.working_days.Monday.to).getMinutes()):'0'
            +(new Date(row.item.working_days.Monday.to).getMinutes()))+(new Date(row.item.working_days.Monday.to).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>

         <template #cell(tuesdayfrom)="row">
           <div :class="row.item.working_days.Tuesday.from=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Tuesday.from!=="off"?((new Date(row.item.working_days.Tuesday.from)).getHours()>12?new Date(row.item.working_days.Tuesday.from).getHours()-12:new Date(row.item.working_days.Tuesday.from).getHours()) +
            ':'+((new Date(row.item.working_days.Tuesday.from).getMinutes())>10?(new Date(row.item.working_days.Tuesday.from).getMinutes()):'0'
            +(new Date(row.item.working_days.Tuesday.from).getMinutes()))+(new Date(row.item.working_days.Tuesday.from).getHours()>=12?' PM':' AM'):'off'}}
           </div>
        </template>
        <template #cell(tuesdayto)="row">
          <div :class="row.item.working_days.Tuesday.to=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Tuesday.to!=="off"?((new Date(row.item.working_days.Tuesday.to)).getHours()>12?new Date(row.item.working_days.Tuesday.to).getHours()-12:new Date(row.item.working_days.Tuesday.to).getHours()) +
            ':'+((new Date(row.item.working_days.Tuesday.to).getMinutes())>10?(new Date(row.item.working_days.Tuesday.to).getMinutes()):'0'
            +(new Date(row.item.working_days.Tuesday.to).getMinutes()))+(new Date(row.item.working_days.Tuesday.to).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>

        <template #cell(wefrom)="row">
          <div :class="row.item.working_days.Wednesday.from=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Wednesday.to!=="off"?((new Date(row.item.working_days.Wednesday.from)).getHours()>12?new Date(row.item.working_days.Wednesday.from).getHours()-12:new Date(row.item.working_days.Wednesday.from).getHours()) +
            ':'+((new Date(row.item.working_days.Wednesday.from).getMinutes())>10?(new Date(row.item.working_days.Wednesday.from).getMinutes()):'0'
            +(new Date(row.item.working_days.Wednesday.from).getMinutes()))+(new Date(row.item.working_days.Wednesday.from).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>
        <template #cell(weto)="row">
          <div :class="row.item.working_days.Wednesday.to=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Wednesday.to!=="off"?((new Date(row.item.working_days.Wednesday.to)).getHours()>12?new Date(row.item.working_days.Wednesday.to).getHours()-12:new Date(row.item.working_days.Wednesday.to).getHours()) +
            ':'+((new Date(row.item.working_days.Wednesday.to).getMinutes())>10?(new Date(row.item.working_days.Wednesday.to).getMinutes()):'0'
            +(new Date(row.item.working_days.Wednesday.to).getMinutes()))+(new Date(row.item.working_days.Wednesday.to).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>

        <template #cell(thfrom)="row">
          <div :class="row.item.working_days.Thursday.from=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Thursday.from!=="off"?((new Date(row.item.working_days.Thursday.from)).getHours()>12?new Date(row.item.working_days.Thursday.from).getHours()-12:new Date(row.item.working_days.Thursday.from).getHours()) +
            ':'+((new Date(row.item.working_days.Thursday.from).getMinutes())>10?(new Date(row.item.working_days.Thursday.from).getMinutes()):'0'
            +(new Date(row.item.working_days.Thursday.from).getMinutes()))+(new Date(row.item.working_days.Thursday.from).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>
        <template #cell(thto)="row">
          <div :class="row.item.working_days.Thursday.to=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Thursday.to!=="off"?((new Date(row.item.working_days.Thursday.to)).getHours()>12?new Date(row.item.working_days.Thursday.to).getHours()-12:new Date(row.item.working_days.Thursday.to).getHours()) +
            ':'+((new Date(row.item.working_days.Thursday.to).getMinutes())>10?(new Date(row.item.working_days.Thursday.to).getMinutes()):'0'
            +(new Date(row.item.working_days.Thursday.to).getMinutes()))+(new Date(row.item.working_days.Thursday.to).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>

        <template #cell(frfrom)="row">
          <div :class="row.item.working_days.Friday.from=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Friday.from!=="off"?((new Date(row.item.working_days.Friday.from)).getHours()>12?new Date(row.item.working_days.Friday.from).getHours()-12:new Date(row.item.working_days.Friday.from).getHours()) +
            ':'+((new Date(row.item.working_days.Friday.from).getMinutes())>10?(new Date(row.item.working_days.Friday.from).getMinutes()):'0'
            +(new Date(row.item.working_days.Friday.from).getMinutes()))+(new Date(row.item.working_days.Friday.from).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>
        <template #cell(frto)="row">
          <div :class="row.item.working_days.Friday.to=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Friday.to!=="off"?((new Date(row.item.working_days.Friday.to)).getHours()>12?new Date(row.item.working_days.Friday.to).getHours()-12:new Date(row.item.working_days.Friday.to).getHours()) +
            ':'+((new Date(row.item.working_days.Friday.to).getMinutes())>10?(new Date(row.item.working_days.Friday.to).getMinutes()):'0'
            +(new Date(row.item.working_days.Friday.to).getMinutes()))+(new Date(row.item.working_days.Friday.to).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>

        <template #cell(satfrom)="row">
          <div :class="row.item.working_days.Saturday.from=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Saturday.from!=="off"?((new Date(row.item.working_days.Saturday.from)).getHours()>12?new Date(row.item.working_days.Saturday.from).getHours()-12:new Date(row.item.working_days.Saturday.from).getHours()) +
            ':'+((new Date(row.item.working_days.Saturday.from).getMinutes())>10?(new Date(row.item.working_days.Saturday.from).getMinutes()):'0'
            +(new Date(row.item.working_days.Saturday.from).getMinutes()))+(new Date(row.item.working_days.Saturday.from).getHours()>=12?' PM':' AM'):'off'}}
          </div>
        </template>
        <template #cell(satto)="row">
           <div :class="row.item.working_days.Saturday.to=='off'?'bg-warning':''" style="font-size:11px">
          {{row.item.working_days.Saturday.to!=="off"?((new Date(row.item.working_days.Saturday.to)).getHours()>12?new Date(row.item.working_days.Saturday.to).getHours()-12:new Date(row.item.working_days.Saturday.to).getHours()) +
            ':'+((new Date(row.item.working_days.Saturday.to).getMinutes())>10?(new Date(row.item.working_days.Saturday.to).getMinutes()):'0'
            +(new Date(row.item.working_days.Saturday.to).getMinutes()))+(new Date(row.item.working_days.Saturday.to).getHours()>=12?' PM':' AM'):'off'}}
           </div>
        </template>
        <!--<template #cell(working_days)="row">
          <b-badge
            variant="primary"
            v-for="day in JSON.parse(row.item.working_days)"
            :key="day"
            class="mr-1 mt-2"
          >
            {{ day }}
          </b-badge>
        </template>

        <template #cell(workinghourchargesmode)="row">
          <b-badge
            variant="info"
            
            class="mr-1 mt-2"
          >
            {{ row.item.workinghourchargesmode }}
          </b-badge>
        </template>

        <template #cell(drivers)="row">
          <b-badge
            variant="warning"
            v-for="driver in row.item.drivers"
            :key="driver.id"
            class="mr-1 mt-2 "
          >
            {{ driver.full_name }}
          </b-badge>
        </template>

        <template #cell(working_hours_from)="row">
          {{
            ((new Date(row.item.working_hours_from)).getHours()>12?new Date(row.item.working_hours_from).getHours()-12:new Date(row.item.working_hours_from).getHours()) +
            ':'+((new Date(row.item.working_hours_from).getMinutes())>10?(new Date(row.item.working_hours_from).getMinutes()):'0'+(new Date(row.item.working_hours_from).getMinutes()))+(new Date(row.item.working_hours_from).getHours()>=12?' PM':' AM')
          }}
        </template>

        <template #cell(working_hours_to)="row">
          {{
            ((new Date(row.item.working_hours_to)).getHours()>12?new Date(row.item.working_hours_to).getHours()-12:new Date(row.item.working_hours_to).getHours()) +
            ':'+((new Date(row.item.working_hours_to).getMinutes())>10?(new Date(row.item.working_hours_to).getMinutes()):'0'+(new Date(row.item.working_hours_to).getMinutes()))+(new Date(row.item.working_hours_to).getHours()>=12?' PM':' AM')
          }}
        </template>-->



        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

     <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination> -->

      <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link" @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link" @click="next">{{$t('Next')}}</a></li>
  </ul>
    </div>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title=" 'Tarrif Deletion'"
      hide-footer
    >
      <p>
        Are you sure that you want to delete this?
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteQeue">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "QueuesTable",
  props:['weekDays'],
  async mounted() {
    /*var s = new Date()
    s.getUTCMonth*/
    this.isBusy = true
    await this.$store.dispatch("dschedule/loadAll").then((res)=>{
      //console.log(res.data)
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      this.isBusy = false
    })
    //console.log(this.weekDays)

    //console.log(this.table.items)
  },

  computed: {
    ...mapGetters({ table: "dschedule/table" }),
    //...mapGetters({ tableAR: "queues/tableAR" }),
  },
  data: function () {
    return {
      isBusy:'',
      schedule: {
        id: null,
      },
      testString:'',
      tempId: null,
      tempName: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:'',
      

    };
  },
  methods: {
    driverPage(id){
      this.$router.push({ name: "ViewDriver", params: { id: id } });
    },
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      this.isBusy = true
      await this.$store.dispatch("dschedule/loadPage", this.currentPage);
      await this.$store.dispatch("drivers/loadPage", this.currentPage);
      this.isBusy = false
    },
    viewSchedule(id) {
      this.$router.push({ name: "ViewSchedule", params: { id: id } });
    },
    async deleteQeue() {
      this.schedule.id = this.tempId;
      await this.$store.dispatch("dschedule/delete", this.schedule);
      await this.$store.dispatch("dschedule/loadAll").then((res) => {
        //this.totalRows = res.data.total;
        console.log(res)
      });
      this.hideModal();
    },

    showModal(id, name) {
        
      this.$refs["my-modal"].show();
      this.tempId = id;
      this.tempName = name;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
<style lang="css" scoped>
.trigger:hover{
  cursor: pointer;
  color: rgb(26, 87, 167);
}
</style>